import React, { useState, useEffect } from "react"

function PrivacyText() {

  return (
    <div>
    <h1>Privacy Policy - Inspection Support Network</h1>
        <p>Last Updated: November 26, 2021</p>
        <h3>Overview</h3>
        <p>This Privacy Policy describes how Inspection Support LLC (“ISN,” “we”) collects, uses, and shares personal information. This Privacy Policy applies to personal information we collect when you access or use our websites, mobile applications, telephone service or other platforms or properties (the “Properties”), use our products and services (individually and collectively with the Properties, the “Services”) or otherwise interact with us. This Privacy Policy also applies to personal information we collect from other sources, including ISN’s parent company Porch.com, Inc. and its subsidiaries (each, a “Porch Family Company”).</p>
        <p>By &#8220;personal information,&#8221; we mean information that uniquely identifies an individual, such as your or your client’s name, email address, or government-issued identification number (such as a driver’s license number or social security number); unique financial information, such as a credit card number; and information that we link to an individual’s unique identifier(s) or unique financial information. If information is de-linked or de-identified, we do not consider it to be personal information. For example, a postal address would not be personal information if it were included in a list of addresses without names or other unique identifiers. <a>Business information (such as the name of a business or the business address or phone number) is not personal information</a>.</p>
        <p>References to ISN (or “we”) also include companies providing services to ISN, the Porch Family Companies, and our business partners, to the extent that such parties are collecting, using, or sharing personal information for or on behalf of ISN. This Privacy Policy applies to information collected by those companies only when they provide it to ISN.</p>
        <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by posting the updated Privacy Policy on our website and updating the effective date at the top of this Privacy Policy and, in some cases, we may provide you with additional notice (such as adding a statement to our websites’ homepages or sending you a notification). &nbsp; </p>
        <p>If you do not agree with the terms of this Privacy Policy, please do not continue using the Services.</p>
        <h3>Information We Collect</h3>
        <p><strong><em>Information You Give to Us</em></strong></p>
        <p>We collect information that you or your account users provide directly to us. For example, we collect your name, email address and other information when you open an account; make a purchase; request customer support; leave a review about your experiences with IS<a>N or otherwise upload, link or provide content that includes personal information</a>; or subscribe to our promotional marketing programs. We collect information about your clients in connection with the services you provide to them through our platform, such as when you submit an inspection report.</p>
        <p><strong><em>Information We Collect Automatically</em></strong></p>
        <p>When you access or use the Services or when we send you emails, we and our advertisers may use tracking technologies to automatically collect information about you. Please see our <a href="https://www.inspectionsupport.com/cookie-policy/">Cookie Policy</a> to learn more about these technologies, how we use them, and how to manage or disable them. Automatically collected information may be associated with your unique browser, device identifier or Internet protocol (IP) address, but we do not consider it to be personal information unless it is linked to your unique identifier(s).</p>
        <p>Automatically collected information includes:</p>
        <ul><li>Log Information. We log information such as IP address, access times, operating system, browser type and language, Internet service provider (ISP), and the web page you visited before navigating to our Services.</li><li>Usage and Session Information. We collect usage information, such as how many visitors visit a specific page, how long they stay, which links they click on, and how, when and how often they order particular services, and session information, such as mouse actions, pages visited, screen actions and other interaction patterns.</li><li>Device Information. We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</li><li>Location Data. If you are using mobile Services, we may collect location data directly from your mobile device if your device allows us to do so. In addition, your IP address may provide an approximate geo-location.</li><li>Transaction Information. When you purchase a service, we collect information about the transaction, such as the date and location of the purchase.</li><li>Emails. When you receive emails from us, we collect information about whether and when you opened the email, whether you clicked on the images and links in the email, and whether your computer or mobile device is capable of receiving HTML-based email.</li></ul>
        <p><em><strong>Information Collected from Calls</strong></em></p>
        <p>When you call us, when we call you, we may monitor and/or record those calls for quality assurance and customer satisfaction purposes. In those jurisdictions where consent is required for a party to record a telephone call, you consent to us recording such calls. We treat the records of these calls, when linked to your unique identifier(s), as personal information.</p>
        <p><strong><em>Information We Collect from Porch Family Companies and Third Parties</em></strong></p>
        <p>The Porch Family Companies provide ISN with information they collect from their customers and business partners.&nbsp; For example, a Porch Family Company may share customer information with ISN for system configuration purposes.</p>
        <p>ISN also obtains personal information from other sources such as third party data providers and public sources.&nbsp; ISN may match the personal information you provide directly to us (for example, an email address) with information obtained from other sources (such as a name, telephone number, and mailing address).</p>
        <p><strong><em>Aggregate and Anonymized Information</em></strong></p>
        <p>We may also aggregate (group) or de-link/de-identify (anonymize) information. Aggregated, anonymized data is not personal information subject to this Privacy Policy, and we may use it to analyze usage of the Services or for any other purpose. We may also share aggregated and/or anonymized information with Porch Family Companies and our and their business partners. For example, based on the information we have obtained from and about individuals, we may compile a list of addresses that we believe are associated with an individual moving to a new address and provide that list (without any unique identifiers for the individuals) to our business partners.</p>
        <p><strong><em>Categories of Personal Information Collected</em></strong> </p>
        <p>In the last 12 months, ISN has collected the following categories of personal information from the categories of sources described above. We consider this information to be personal information to the extent that it is information that uniquely identifies you, is unique financial information, or is linked to your unique identifier(s), or if it is defined as personal information by applicable state law.</p>
        <table><tbody><tr><td><strong>Category</strong></td><td><strong>Examples</strong></td></tr><tr><td>Identifiers</td><td>Real name, postal address, unique personal identifier, Internet Protocol address, email address, phone number, or other similar identifiers.</td></tr><tr><td>Personal information categories listed in the California customer records statute (Cal. Civ. Code § 1798.80(e))</td><td>Name, telephone number, credit card number, debit card number, or any other financial information.<br/><br/>Address (constitutes personal information in states that designate a postal address as personal information, such as California)<br/><br/><em>Some personal information included in this category may overlap with other categories.</em></td></tr><tr><td>Protected classification characteristics under California or federal law</td><td>None</td></tr><tr><td>Commercial information</td><td>Records of products or services purchased.</td></tr><tr><td>Internet or other similar network activity</td><td>Search history, information on a consumer&#8217;s interaction with a website, application, or advertisement while using or interacting with ISN’s websites and applications.</td></tr><tr><td>Geolocation data</td><td>Physical location or movements.</td></tr><tr><td>Professional or employment-related information</td><td>Current or past job history or performance ratings.</td></tr><tr><td>Inferences drawn from other personal information.</td><td>Profile reflecting a person&#8217;s preferences, characteristics, predispositions, and behavior.</td></tr><tr><td>Audio, electronic, visual, thermal, olfactory, or similar information</td><td>Call recordings</td></tr><tr><td>Biometric information</td><td>None</td></tr><tr><td>Education information</td><td>None</td></tr></tbody></table>
        <h3>How We Use the Information We Collect</h3>
        <p>In general, we use the information we collect to operate our business, including:</p>
        <ul><li>To provide, maintain, improve and personalize the Services and the services of other Porch Family Companies.</li><li>To connect you with clients and other businesses with whom you choose to interact through the Services.</li><li>To process transactions, to provide and improve customer service, to communicate with you about your account, changes in our policies or other service matters, and to otherwise manage our customer relationships.</li><li>To market products and services from ISN, the Porch Family Companies, and our and their business partners, to optimize marketing campaigns, and to analyze their effectiveness, distribution and reach.</li><li>To develop new products and services.</li><li>To administer contests, promotions or sweepstakes.</li><li>To detect, investigate and address technical issues, security incidents, fraudulent transactions, abuse, violations of our agreements and policies, and illegal activities.</li><li>To comply with our legal obligations and legal process.</li><li>To create aggregated or de-identified data sets.</li><li>To carry out certain short-term activities and other reasonable internal purposes related to the Services, the products or services you purchase through us or your ongoing relationship with us.</li><li>To perform other business purposes described at the time the information is collected or as otherwise set forth in applicable data privacy laws.</li></ul>
        <p>ISN will not collect additional categories of personal information or use the personal information we collect for materially different, unrelated, or incompatible purposes without providing you notice.</p>
        <h3>How We Share Information</h3>
        <p>Except as described in this Privacy Policy, we do not sell or rent personal information to any third party. If we share personal information, we do so only as follows:</p>
        <p><strong><em>Porch Family Companies</em></strong></p>
        <p>We share your and your clients’ information with Porch Family Companies for marketing purposes, to allow them to improve their products and services (such as confirming or supplementing information they have about a person or to classify a person as a mover), in connection with the data, technical, legal, accounting, and other&nbsp; services shared between the Porch Family Companies, and for other internal business purposes. The Porch Family Companies will be bound by the terms of this Privacy Policy with respect to personal information received from ISN and will use that personal information only as described in this Privacy Policy. If you are an inspector, you can opt out of sharing your clients’ personal information with Porch Family Companies for marketing and product purposes by disabling Porch Gold in the Services.</p>
        <p><strong><em>ISN Partners</em></strong>&nbsp;</p>
        <p>We share your business information (and any personal information included in it) with selected companies with whom we partner (“ISN Partners”) for the purpose of offering an expanded range of business services to support, facilitate and augment home inspection-related services. ISN Partners with whom we may share this information include the companies listed on the our Partner Page of our Services, <a href="https://www.inspectionsupport.com/partners/"><strong>here</strong></a>.  ISN Partners will use personal information in accordance with their respective privacy policies, which we encourage you to review if you have questions regarding how they will handle personal information provided by ISN. ISN Partners are not allowed to use personal information received from ISN in a manner prohibited under this policy.</p>
        <p><strong><em>Companies Providing Services to ISN</em></strong></p>
        <p>We share information with third-party vendors, consultants, and other service providers that are providing business services to ISN. For instance, we may share information about you or your clients with a third-party service provider that hosts the Properties, provides call center or other customer service, creates reports or other products ordered by you or your clients, sends out emails, serves ads to you on the Properties or on a third-party platform, or monitors or analyzes Services data. Such companies will have access to personal information only as necessary to carry out their work and are prohibited from using it for any other purpose.</p>
        <p><strong><em>User-Directed Disclosures</em></strong></p>
        <p>We share your personal information when you direct us to do something that requires its disclosure or when you otherwise provide your consent. For example, if you request a repair estimate report on behalf of your client, we will provide the inspection report (including the client’s personal information) to the company that prepares the report.</p>
        <p><strong><em>Companies with Whom There Is an Existing Relationship</em></strong></p>
        <p>If an individual is already a customer of or otherwise has a direct relationship with a business partner of ISN or a Porch Family Company (an “Existing Relationship Company”), we or the Porch Family Company may disclose certain of information about that person to that Existing Relationship Company. If the person is a match to a customer on the Existing Relationship Company customer list, we may provide the Existing Relationship Customer with information such as the groups or segments the person belongs to (such as mover or homeowner) and demographic information about the person and his or her home (such as estimated household income and square footage), so that the Existing Relationship Company can provide with more relevant advertising, products and services. We will not give the Existing Relationship Company any contact information, unique personal identifiers, or unique financial information unless for another purpose permitted in this Privacy Policy. However, the Existing Relationship Company may already have some or all of that information (because of its existing relationship with the person) and they will be able to link the person with the other information about him or her that we provide to them.</p>
        <p><strong><em>Contests and Sweepstakes</em></strong></p>
        <p>When you sign up for and participate in a contest or sweepstakes, we may publicly disclose the name, city and state of winners.</p>
        <p><strong><em>Compliance with Laws and Policies; Protection of Rights</em></strong></p>
        <p>We may disclose your personal information if we believe disclosure is necessary to comply with any applicable law, regulation, or legal process, to cooperate with government or law enforcement, or to avoid legal liability; to resolve complaints or disputes; to investigate and resolve technical issues, security incidents, fraud, abuse, and illegal activities; if we believe your actions are inconsistent with the spirit or language of our user agreements or policies; or to protect the rights, reputation, safety and property of ISN or others.</p>
        <p><strong><em>Strategic Transactions</em></strong></p>
        <p>We may disclose personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business or in a bankruptcy, liquidation, dissolution or similar transaction. In the event that we or all of our assets are acquired in such a transaction, our collected information would be one of the transferred assets.</p>
        <p><strong><em>Professional Information</em></strong></p>
        <p>We do not consider business information to be personal information. Accordingly, we may share business names, owner/proprietor names, business contact information and other information with third parties for any purpose.</p>
        <p><strong><em>Categories of Personal Information Disclosed</em></strong></p>
        <p>In the last 12 months, ISN has disclosed the following categories of personal information for a business purpose to the following categories of third parties:</p>
    <table>
    <tbody>
    <tr>
    <td>
    <strong>Category of Information</strong>
    </td>
    <td>
    <strong>Category of Third Parties</strong>
    </td>
    </tr>
    <tr>
    <td>Identifiers</td>
    <td>Companies providing business services to ISN<br/>
    <br/>Porch Family Companies<br/>
    <br/>ISN Partners<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above<br/>
    <br/>Counterparties in financings, asset sales or other strategic transactions as described in&nbsp;<em>Strategic Transactions</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
    <td>Companies providing business services to ISN<br/>
    <br/>Third parties upon a request by the user that requires disclosure<br/>
    <br/>Porch Family Companies<br/>
    <br/>ISN Partners<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above<br/>
    <br/>Counterparties in financings, asset sales or other strategic transactions as described in&nbsp;<em>Strategic Transactions</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Protected classification characteristics under California or federal law</td>
    <td>Companies providing business services to ISN<br/>
    <br/>Third parties upon a request by the user that requires disclosure<br/>
    <br/>Porch Family Companies<br/>
    <br/>ISN Partners<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Commercial Information</td>
    <td>Companies providing business services to ISN<br/>
    <br/>Third parties upon a request by the user that requires disclosure<br/>
    <br/>Porch Family Companies<br/>
    <br/>ISN Partners<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above<br/>
    <br/>Counterparties in financings, asset sales or other strategic transactions as described in&nbsp;<em>Strategic Transactions</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Internet or other similar network activity</td>
    <td>Companies providing business services to ISN&nbsp;<br/>
    <br/>Porch Family Companies<br/>
    <br/>Advertising networks<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Geolocation data</td>
    <td>None (except approximate geo-location data included in information in other categories)</td>
    </tr>
    <tr>
    <td>Professional or employment-related information</td>
    <td>Companies providing business services to ISN<br/>
    <br/>Third parties upon a request by the user that requires disclosure<br/>
    <br/>Porch Family Companies<br/>
    <br/>ISN Partners<br/>
    <br/>Courts, government officials, attorneys, investigators and other third parties as described in&nbsp;<em>Compliance with Laws and Policies; Protection of Rights</em>&nbsp;above<br/>
    <br/>Counterparties in financings, asset sales or other strategic transactions as described in&nbsp;<em>Strategic Transactions</em>&nbsp;above</td>
    </tr>
    <tr>
    <td>Inferences drawn from other personal information</td>
    <td>Porch Family Companies<br/>
    <br/>Existing Relationship Companies&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <h3>Security</h3>
    <p>We take reasonable measures, including administrative, technical, and physical safeguards, to restrict access to your personal information and help protect it from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. For example, when you enter a credit card number on the Properties, we encrypt that information in transit over the Internet and when stored. &nbsp;However, no such measures are 100% secure.</p>
    <h3>Third-Party Websites or Services</h3>

    <p>The ISN website may contain links to other third-party websites and services, either directly or through advertisements on ISN’s website.&nbsp; ISN is not responsible for the content or privacy practices of websites operated by third parties, including our advertising partners and networks.&nbsp; We encourage you to review the privacy policies of third-party websites and services before providing any information to them.review the privacy policies of any third-party websites or applications.</p>
    <h3>Managing Your Personal Information</h3>
    <p><strong><em>Account Information</em></strong></p>
    <p>You may access, update and correct your online account information at any time by logging into your online account and updating your preferences. If you wish to delete your online account, please email us at <a href="mailto:help@inspectionsupport.net">help@inspectionsupport.com</a>. Please note that, even if your account is deleted, information that you chose to provide in a public area of the Services (such as reviews) will remain visible to the public on other sites and may be retained by third parties with whom such information has been shared, and we may retain your information and that of your clients as required by law or for legitimate business purposes. We may also retain cached or archived copies of your information.</p>
    <p>You may also request to access information or to update or correct inaccurate information by emailing us at <a href="mailto:help@inspectionsupport.net">help@inspectionsupport.com</a> or writing us at Inspection Support LLC, 2200 1st Avenue South, Seattle, WA 98134.</p>
    <p>If you are a client of an inspector and wish to correct your information in the inspector’s records or ISN account, please contact the inspector.</p>
    <p><strong><em>Cookies</em></strong></p>
    <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of the Services.</p>
    <p>To opt out of being tracked by Google Analytics when using our web-based services, visit https://tools.google.com/dlpage/gaoptout.</p>
    <p><strong><em>Promotional Communications</em></strong></p>
    <p>Receiving promotional calls, text (SMS) messages, emails and direct mail from ISN is completely voluntary, and you may opt out at any time. To opt out of promotional communications from ISN, email us at <a href="mailto:help@inspectionsupport.net">help@inspectionsupport.com</a>. You can also opt out of receiving promotional emails by clicking the unsubscribe link in the email, and you can opt out of promotional text (SMS) messages by texting STOP. You may receive a final email or text message confirming your opt-out. Message and data rates may apply. Please allow up to 10 business days to process any opt-out request.</p>
    <p>Please note that even if you opt out, we may still send you non-promotional communications, such as emails about site outages, your online account or service requests that you have submitted.</p>
    <p><strong><em>Communications By Third Parties</em></strong></p>
    <p>To opt out from receiving promotional communications from a Porch Family Company or other third party, or to make requests regarding your personal information or other information held by them, you should contact them directly.</p>
    <h3>Children and Our Services</h3>
    <p>Our Services are not directed to children, and you may not use our Services if you are under the age of 16. If we learn that we have collected personal information of a child under 16, we will make commercially reasonable efforts to delete such information from our files.</p>
    <h3>State-Specific Rights</h3>
    <p><strong><em>Your California Resident Privacy Rights</em></strong> </p>
    <p>Under the California Consumer Privacy Act or CCPA, California residents have certain rights regarding their personal information, including:</p>
    <ul><li>The right to know the categories of personal information we’ve collected about them and the categories of sources from which we got the information (see <em>Information We Collect</em>);</li><li>The right to know the business purposes for which we’ve shared their personal information (see <em>How We Use Information We Collect</em>);</li><li>The right to know the categories of third parties with whom we’ve shared their personal information (see <em>How We Share Information We Collect</em>);</li><li>The right to access the specific pieces of personal information we’ve collected about them and to request that we delete personal information, subject to certain exceptions (see below);</li><li>The right to opt out of the sale of their personal information (see below); and</li><li>The right to not be discriminated against for exercising their CCPA privacy rights.<strong></strong></li></ul>
    <p>For purposes of these rights, “sold” and “business purpose” have the meanings given in the CCPA. For example, if you submit an opt-out request, you will not be opted out from transactions that the CCPA does not deem to be sales. In addition, please note that the CCPA does not require us to delete personal information that we need to maintain for certain purposes. To exercise the access and deletion rights described above <a>with respect to your personal information</a>, you may submit a verifiable consumer request to us by either:</p>
    <ul><li>Calling us toll free at (800) 700-8112; or</li><li>Visiting our webpage at www.inspectionsupport.net/ccpa-request/</li></ul>
    <p>To opt out of the sale of your personal information, click the following link:&nbsp;<a href="https://www.inspectionsupport.com/ccpa-do-not-sell/">Do Not Sell My Personal Information</a>.</p>
    <p><a></a><a>Only the individual consumer, or someone that he or she authorizes to act on his or her &nbsp;behalf, may make a verifiable consumer request related to that person’s personal information. </a><a>We will work to respond to a consumer request within 15 business days (for opt-out requests) or 45 calendar days (for deletion and access requests) of receipt. </a>We will not charge you a fee for making a request unless your request is excessive, repetitive, or manifestly unfounded. If we determine that your request warrants a fee, we will notify you and explain our decision before completing your request.</p>
    <p><strong><em>Your Nevada Resident Privacy Rights</em></strong> </p>
    <p>If you are a resident of Nevada, you have the right to opt out of the sale of certain kinds of personal information (such as name, physical address, email address, telephone number and other personal &nbsp;information collected through a website) to purchasers that will then license or sell the covered information to additional persons. You can exercise this right by emailing us at help@inspectionsupport.net with the subject line “Nevada Do Not Sell Request.” Nevada law specifies various transactions that are excluded from the definition of “sale”; if you exercise this opt-out right, you will not be opted out from transactions that the law does not deem to be sales.</p>
    <h3>Retention</h3>
    <p>ISN retains personal information as long as it is needed for business or legal purposes.</p>
    <h3>Governing Law; Visitors Residing Outside the United States</h3>
    <p>ISN’s website and services are hosted and performed in the United States. This Privacy Policy is subject the laws of the United States of America and the State of Washington, without regard to conflict of law principles. If you reside outside of the United States, you are responsible for complying with local laws in connection with your use of the Services, and you consent to the collection, transfer, storage, and use of your personal information in the United States in accordance with this Privacy Policy. The laws governing processing of personal information vary depending on the jurisdiction in which you reside. To the extent the laws of your jurisdiction are more restrictive than the terms of this Privacy Policy, it is ISN’s policy to follow the requirements of those laws to the extent those laws are applicable to ISN’s activities or your personal information.</p>
    <h3>Additional Information or Assistance</h3>
    <p>Please address comments or questions to <a href="mailto:help@inspectionsupport.net">help@inspectionsupport.com</a> or write to us at the address below.</p>
    <p>Inspection Support LLC<br/>Attn: ISN Legal Department<br/>2200 1st Ave S.<br/>Seattle, WA 98134</p>      
  </div>
  )
}

export default PrivacyText
